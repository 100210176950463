@import "../_globalColor";

.dark-mode {
  background-color: $darkBackground;
  color: white;
  transition: "0.1s";
}
.top-div-margin{
  padding-top: 5.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}