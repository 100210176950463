@import "../../_globalColor";

/*React-headroom*/
.headroom-wrapper {
  max-height: 100px;
  top: 0;
  display: flow-root;
}

/* header */

.dark-menu {
  background-color: $darkBackground !important;
  color: $textColorDark;
}

.dark-menu li a {
  color: $textColorDark !important;
}

.dark-menu li a:hover {
  background-color: $buttonColor !important;
}

.header {
  background-color: $lightBackground3;
  max-width: 100%;
  padding: 15px 20px;
  margin: 0px auto;
  position: fixed;
  top:0px;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 1;
  box-shadow: 0 0 0 1px rgb(0 0 0 / 5%), 0 2px 4px 1px rgb(0 0 0 / 9%);
}
.dark-mode .header{
  box-shadow: 0 0 0 1px rgb(0 0 0 / 50%), 0 2px 4px 1px rgb(0 0 0 / 50%);
}

.header ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
}

.grey-color {
  color: $subTitle;
}

.header li a {
  display: block;
  padding: 5px 20px;
  color: $textColor;
  text-decoration: none;
}

.header li a:hover,
.header .menu-btn:hover {
  background-color: $headerHoverBG;
}

.header .logo {
  display: block;
  flex: none;
  float: left;
  font-size: 2rem;
  text-decoration: none;
  // margin-top: 10px;
  line-height: normal;
  margin-bottom: 10px;
}

.header .logo-name {
  // font-family: "Agustina Regular";
  font-weight: bold;
  font-variant-ligatures: no-common-ligatures;
  -webkit-font-variant-ligatures: no-common-ligatures;
  padding: 0 10px;
  margin-left:40px;
}
 .header .logo-image {
  background-image: url('../../assets/images/favicon-32x32.png');
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
}
.header .logo-name:hover{
  // text-shadow: 2px 2px 8px #7c7a7a;
}
.logo-name {
  color: #405723;
}
.dark-menu .logo-name {
  color: white;
}

/* menu */

.header .menu {
  clear: both;
  max-height: 0;
  transition: max-height 0.2s ease-out;
  overflow: auto;
  display: flex;
}

/* menu icon */

.header .menu-icon {
  cursor: pointer;
  display: inline-block;
  float: right;
  padding: 16px 10px;
  position: relative;
  user-select: none;
}

.header .menu-icon .navicon {
  background: $iconBackground;
  display: block;
  height: 2px;
  position: relative;
  transition: background 0.2s ease-out;
  width: 18px;
}

.header .menu-icon .navicon-dark {
  background: $textColorDark;
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
  background: $iconBackground;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 100%;
}

.header .menu-icon .navicon-dark:before,
.header .menu-icon .navicon-dark:after {
  background: $textColorDark !important;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 100%;
}

.header .menu-icon .navicon:before {
  top: 5px;
}

.header .menu-icon .navicon:after {
  top: -5px;
}

/* menu btn */

.header .menu-btn {
  display: none;
}

.header .menu-btn:checked ~ .menu {
  max-height: 486px;
}

.header .menu-btn:checked ~ .menu-icon .navicon {
  background: transparent !important;
}

.header .menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}

.header .menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}

.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}

.header ul.menu > li > a:after {
	// display: block;
	// position: absolute;
	// left: 0;
	// bottom: -1px;
	width: 0;
	height: 2px;
	background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
	content: "";
	transition: width 0.2s;
  display: block;
  margin-top: 2px;
}
.header ul.menu > li:not(:last-child) > a:hover:after {
	width: 100%;
}
.header ul.menu > li:not(:last-child) > a.active:after {
	width: 100%;
}

/* 48em = 768px */

@media (min-width: 48em) {
  .header li {
    float: left;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .header .menu {
    max-height: none;
  }
  .header .menu-icon {
    display: none;
  }


.header .logo {
  font-size: 2rem;
  margin-top: 0px;
}
}
@media (max-width: 960px) and (min-width: 768px) {
  .header {
    // font-size: 12px;
  }
}
