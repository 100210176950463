@import "../../_globalColor";

.upi-card-div {
color: $githubRepoCardColor;
background-color: $lightBackground2;
box-shadow: $lightBoxShadowDark 0px 10px 30px -15px;
padding: 2rem;
cursor: pointer;
-webkit-transition: all 0.3s ease;
-o-transition: all 0.3s ease;
transition: all 0.3s ease;
}
.upi-card-div:hover {
box-shadow: $lightBoxShadowDark 0px 20px 30px -10px;
}

.repo-stats {
display: flex;
-webkit-box-pack: justify;
justify-content: space-between;
//   font-size: 13px;
color: $githubRepoCardRepoCardStatsColor;
}

.repo-left-stat {
-webkit-box-flex: 1;
flex-grow: 1;
display: flex;
letter-spacing: .2rem;
}

.language-color {
width: 10px;
height: 10px;
background-color: $githubRepoCardLanguageColorBG;
margin-right: 0.25rem;
border-radius: 100%;
}

.repo-left-stat span {
display: flex;
-webkit-box-align: center;
align-items: center;
margin-right: 0.75rem;
}

.bank-name-div {
display: flex;
align-items: center;
}

.repo-svg {
margin-right: 0.5rem;
min-width: 16px;
}

.repo-name {
white-space: nowrap;
text-overflow: ellipsis;
color: $githubRepoCardRepoNameColor;
margin-bottom: 0.75rem;
font-size: 25px;
font-weight: 700;
letter-spacing: -0.5px;
overflow: hidden;
margin: 0px;
height: 30px;
}

.repo-star-svg {
margin-right: 0.3rem;
}

.repo-description {
overflow: hidden;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
}

.dark-card-mode {
background-color: $darkBackground;
color: $textColorDark;
}
.dark-card-mode p {
color: $textColorDark;
}
.dark-card-mode:hover {
background-color: $buttonColor !important;
box-shadow: 0px 0px 16px $darkBoxShadow;
}

.upi-payment{
display: flex;
padding: 5px;
}
.upi-option{
height: 42px;
width: 49px;
background-repeat: no-repeat;
background-size: cover;
}
.google-pay-icon{
background-image: url('../../assets/images/google-pay.png');
}
.paytm-icons{
background-image: url('../../assets/images/Paytm.png');
}
.upi-name-div{
display: block;
}
@media (max-width: 768px) {

.repo-left-stat {
flex-direction: column;
}
.upi-name-div{
display: block;
}

.upi-payment {
  display: inline-flex;
}
}