@import "../../_globalColor";

// .gallery-div-main h1 {
//  margin:0px
// }

.dark-mode a {
  color: $textColorDark;
}

// .gallery-div-main > * {
//   flex: 1;
// }

.contact-title {
  font-size: 65px;
  font-weight: 400;
}

.contact-detail,
.contact-detail-email {
  margin-top: 20px;
  font-size: 40px;
  font-weight: 400;
  color: $subTitle;
  text-decoration: none;
}

.contact-detail:hover,
.contact-detail-email:hover {
  color: $textColor;
  text-shadow: 2px 1px 2px $contactDetailHoverTS;
  transition: all 0.3s;
}

.contact-subtitle {
  text-transform: uppercase;
}

.contact-text-div {
  margin-top: 1.5rem;
}

.gallery-margin-top{
    // margin-top: 4rem;
}

.contact-image-div > img {
  max-width: 100%;
  height: auto;
  margin-left: 1.5rem;
  margin-top: -4rem;
}
.carousel-container{
    // margin-top: 4.5rem;
}

.dark-mode .__JnHV{
    background:rgb(0 0 0 / 10%) !important;
  }
/* Media Query */
@media (max-width: 1380px), (max-width: 768px) {
  .contact-title {
    font-size: 56px;
    font-weight: 400;
    text-align: center;
  }

  .contact-title {
    font-size: 40px;
    text-align: center;
  }

  .contact-subtitle {
    font-size: 16px;
    line-height: normal;
    text-align: center;
  }

  .contact-text-div {
    text-align: center;
    margin-top: 1.5rem;
  }

  .contact-detail,
  .contact-detail-email {
    font-size: 20px;
  }

  .contact-image-div {
    display: none;
  }
}
