@import "../../_globalColor";

.donate-main-div {
  display: flex;
}

.donate-text-div {
  margin-left: 50px;
}

.donate-main-div > * {
  flex: 1;
  margin-bottom: 30px;
  margin-top:4rem;
  margin-right: 1rem;
}

.donate-image-div > img {
  max-width: 100%;
  height: auto;
}

.donate-heading {
  font-size: 56px;
  font-weight: 400;
}
.subTitle {
  color: $subTitle;
}
// .donate-top-margin{
//   padding-top: 5rem;
// }
/* Media Query */
@media (max-width: 1380px) {
  .donate-heading {
    font-size: 40px;
  }
}
@media (max-width: 768px) {
  .donate-heading {
    font-size: 30px;
    text-align: center;
  }
  .greeting-text-p {
    font-size: 16px;
    line-height: normal;
  }
  .donate-main-div {
    flex-direction: column;
  }
  .donate-text-div {
    margin-left: 0px;
    padding-top: 5rem;
  }
  .donate-text {
    font-size: 16px;
  }
  .donate-text-subtitle {
    font-size: 16px;
    text-align: center;
  }
  .donate-image-div {
    /* display: none; */
    order: 2;
  }
}
